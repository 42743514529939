export const NewsContents = [
  {
    "title": "First News of de Hemptinne Lab",
    "type": "press",
    "date": new Date("2023-12-07").getTime(),
    "link": "/news/20231207_firstnews",
    "image": "",
    "author": "Jackson Cagle",
    "component": require("./20231207-firstnews.js").NewsComponent,
    "shortContent": "This is our first news! ",
  }
];