import React from "react";

import {
  Container,
  Row, 
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

const CurrentProjects = [
  {
    title: "Evoked Resonant Neural Activity",
    subtitle: "Intraoperative Study",
    descriptionShort: `
      Pallidal evoked resonant neural activity shows promise as a potential marker to guide intraoperative targeting 
      and to assist the clinician with postoperative stimulation programming. Importantly, evoked resonant neural activity may also have 
      the potential to guide directional and closed-loop deep brain stimulation programming for Parkinson’s disease.
    `,
    link: "/projects/ERNA",
    img: "https://oup.silverchair-cdn.com/oup/backfile/Content_public/Journal/braincomms/5/2/10.1093_braincomms_fcad025/2/fcad025_ga1.jpeg?Expires=1704915910&Signature=roQIsroFRJjYtR~vbuAmrSf9KLrx1jrS1aERGSNwg5zhQ9vkLjkVwB8ntsleGAsTwTRkeu3SS5sKqa8uOXazcrEdp1ziW7C~BdjhZl0DtsyNI9Hznh3Z1iDjAchOQTq12zJvHzyL0mn2ZG6HOfKwn2aOb5tktLutR7Wc9SYdpFfzA5PtoRrszYh4Uv9GZaEn1QkY3-bE2b5yfI69ooJ3UzzbaSkjhpkKOZXn6r8pPcmHanmZE24pOXnzPqdTDGH3aWw6f~GgVjzSZ5P4xAKMIUNtmi8uIyC2kv9sBHqHbXNaOZa6HsisIDiryViFMMK-ZC22OHPI3ixEI6RcP-8p6Q__&Key-Pair-Id=APKAIE5G5CRDK6RD3PGA"
  },
  {
    title: "Brain Recording Analysis and Visualization Online Platform",
    subtitle: "Open-source Software",
    descriptionShort: `
      The Brain Recording Analysis and Visualization Online (BRAVO) platform was developed to easily import, visualize, and analyze brain signals. 
      This Python-based web interface has been designed and implemented on a Linux server. The tool processes the session files from DBS programming 
      generated by a clinical 'programming' tablet. The platform is capable of parsing and organizing neural recordings for longitudinal analysis. 
    `,
    link: "/projects/BRAVO",
    img: require("assets/img/projects/BRAVO_Demo.png")
  },
  {
    title: "Circadian Adaptive DBS for Essential Tremor",
    subtitle: "Clinical Trial",
    descriptionShort: `
      Circadian Adaptive DBS (aDBS) for Essential Tremor Trial aim to automatically adjust therapy based on sleep/wake state of the patients using 
      purely neural signals. The aDBS therapy will turn off stimulation at night to conserve battery for the patients and avoid habituation from overstimulation.
    `,
    link: "/projects/circadian-aDBS-ET",
    img: "https://www.tga.gov.au/sites/default/files/medtronic-deep-brain-stimulation-therapy-extension-models-7483-37086-01.jpg"
  },
]

export default function Section({id}) {


  return (
    <div id={id} className="projects-2 section section-dark">
    <Container>
      <Row>
        <Col className="ml-auto mr-auto text-center" md="8">
          <h6 className="text-muted">Project Showcase</h6>
          <h2 className="title">Current Ongoing Research Projects</h2>
        </Col>
      </Row>
      <div className="space-top" />
      <Row>
        {CurrentProjects.map((project) => {
          return (
            <Col key={project.title} lg={CurrentProjects.length < 3 ? "6" : "4"}  style={{display: "flex", alignItems: "center"}}>
              <Card className="card-plain">
                <CardImg top tag="div" style={{display: "block", maxHeight: 300, borderRadius: 20, overflow: "hidden"}}>
                  {project.img ? (
                  <a
                    href={project.link}
                    style={{width: "100%"}}
                  >
                    <img
                      alt="..."
                      className="img"
                      src={project.img}
                      style={{width: "100%"}}
                    />
                  </a>
                  ) : null}
                </CardImg>
                <CardBody>
                  <a
                    href={project.link}
                  >
                    <CardTitle tag="h4">{project.title}</CardTitle>
                    <br />
                  </a>
                  <h6 className="card-category text-muted">{project.subtitle}</h6>
                  <p className="card-description">
                    {project.descriptionShort}
                  </p>
                </CardBody>
              </Card>
            </Col>
          )
        })}
      </Row>
    </Container>
  </div>
  )
};