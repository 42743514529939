import React from "react";

// reactstrap components
import { Badge, Button, Card, CarouselIndicators, CarouselCaption, CarouselItem, Carousel, Media, Container, Row, Col } from "reactstrap";

import ReactImageGallery from "react-image-gallery";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import BlogPostHeader from "components/Headers/BlogPostHeader.js";
import FooterGray from "components/Footers/FooterGray.js";
import FooterWhite from "components/Footers/FooterWhite";

const ProjectFigures = [
  {
    original: "https://oup.silverchair-cdn.com/oup/backfile/Content_public/Journal/braincomms/5/2/10.1093_braincomms_fcad025/2/fcad025f1.jpeg?Expires=1705076845&Signature=En9IXDK-joQu98jpa5Bqo8D48-WtsjQjoPBtu9HFOx6liqTg72UcP2jpMgWeSJUbQ8pOSzQQLgFzj4KQFUMdfoT3eWSppohVGp0-hGAPbNXzweZjKQ8MZtvSilClOCeFGNKJuR53FRnynmlNcraJGvtk2YHh-4o~pjr2UMr8ONc6vvq3hfovdRC5thX~Q1s53ir9l~vEsP4EehGvOmEIjG4tGwN07gRkWx9~Q9r1l8YBEDzj3aMf7rpx5bKohGtazcSvZtFU7~lGRB~qjiAsu4OdCaypmTKNk8mD1sKyqegzmEBP3u8JlfdrNF5Qwzfri2FkjAOxcJdIWgzCDPzBFw__&Key-Pair-Id=APKAIE5G5CRDK6RD3PGA",
    altText: "",
    caption: "Figure 1",
    header: ""
  },
  {
    original: "https://oup.silverchair-cdn.com/oup/backfile/Content_public/Journal/braincomms/5/2/10.1093_braincomms_fcad025/2/fcad025f2.jpeg?Expires=1705076781&Signature=Tt9WlafY-inzBYyrvzWSEQiKbnoXZxrWKFnuNBaYGioPESxdoGN5NhGE3OHBqvNUg7ta9yW~1pkVdNb3arlgdo3CjmuQ8hB6yYhMHZrtGuVmX88r33sLe1BFlXObJd5KC0pnHBXemgEawC3Cwd8WSPkMt85C7ltYsEbw8Q~IoTfXwJx8ExdQ2MeqfOjXyvzFdgZ9itwzBPJYj2Kx9qIhdAAeSfjlY~mklsFkU1Du458StbQO~M7qhkwskNkavfGsttqCv9k4zwi8dJMToE-rIgdOVYF0O~Uz-6cFEt3Cglkotpy6vQeYy8JLhLRExoEO34hnwt4y~rcBHXVh3Mdcag__&Key-Pair-Id=APKAIE5G5CRDK6RD3PGA",
    altText: "",
    caption: "",
    header: ""
  },
  {
    original: "https://oup.silverchair-cdn.com/oup/backfile/Content_public/Journal/braincomms/5/2/10.1093_braincomms_fcad025/2/fcad025f3.jpeg?Expires=1705076859&Signature=zSkWh47rggi8mbhdwmIHdM-W-DiaXrbB1LLz7BfxFzA7mknmeOMEfMkXnKRwsvmomfkpR83hA9jMHRaFC9wAVn37VUZkAxXd95sjXx6UOTNgklRAO80P88hQPZ42kny3LcetYxN88gIDmBVBn4yIi60vyB~18hbNwa3GAOEDDL0I6zsx7ClUwb-TOYw5PL4cjpCBR3aDqOjaHMIsSRbOb6dHvZdlzL3zDMvUrxO~ttJLNa2IFAhng9ihPc-YJwUqqgaWOPNALq3DiT-buZEShIdAm6L8FIq7UbuqXG~UyaGhe6T2RtNuokIRH6~ZzAM5AD~ZsKX65Pa8bW~eaLFcTw__&Key-Pair-Id=APKAIE5G5CRDK6RD3PGA",
    altText: "",
    caption: "",
    header: ""
  },
  {
    original: "https://oup.silverchair-cdn.com/oup/backfile/Content_public/Journal/braincomms/5/2/10.1093_braincomms_fcad025/2/fcad025f4.jpeg?Expires=1705076882&Signature=KAUb6Ql2F4DHHeRvoZmMS45Lyjz1zYed7Li8y52nHM-w2yy6jWjqFwKs2rNIJPwCBMf8Mliysz5fUKn4zcb1cntGvMiXpWl9Zj8P-zd7hfE~xT9qfnjuSN424MTw54oseDCdPL1zSScjldmcqOEUDq2s68lwFANOsaO6vGx6jOYPFxkYgOdUhKgZzxgjE2Bowvx4HCQRKXRnoGfaREM8~autT1aav~Ev3JlJB2UXlV-d~6ImIruaCHQ6zWFnCBOEQil7kTEtoLh1hKsjaOIi4uvOq~G2mkl0WsbCKHzLvhbpEif027so01ifW-KOA5ufrDTT038bksgV~IOXcMBhiQ__&Key-Pair-Id=APKAIE5G5CRDK6RD3PGA",
    altText: "",
    caption: "",
    header: ""
  }
]

export default function ERNA() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("project-post");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("project-post");
    };
  }, []);

  // carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === ProjectFigures.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? ProjectFigures.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <ColorNavbar always/>
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container style={{maxWidth: 1600}}>
              <Row>
                <Col className="ml-auto mr-auto text-center title" md="6">
                  <h2>Evokes Resonant Neural Activity (ERNA) in Parkinson’s Disease</h2>
                  <h3 className="title mt-2">
                    <small>By Kara A. Johnson and Colleagues</small>
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <Card
                    data-radius="none"
                  >
                    <img 
                      src={"https://oup.silverchair-cdn.com/oup/backfile/Content_public/Journal/braincomms/5/2/10.1093_braincomms_fcad025/2/fcad025_ga1.jpeg?Expires=1705075004&Signature=GHXBK-CxQ15vi1WBh1M4-PfLR9uAFW5L-QJruTS3SYbWhKInXko9orQCfcO2rWCt2KzCjCcMITcVpUw1LPF78bq-yLJL5ls3vQjD76J1CNwe4Amjvt~L35hHXJvzn3DyT0s46skOnWfvi9NIqWM1WiE-jSiHZy4rOdCK80vAP9osSwRIEp014y0cJBom0K~WSk4H7DX2zyX2PVYsgmRhzsnt-7GhD-sHRwbY80HTK-qhi1Uh4M5RkdMcyvCt1K51zOviNJg79YS-pzIgp8HAmZWfrSo8xvzXRjl7o7--ahqM1UAW9VE8u4iPeVdvz28yd1dQfnXFKq7-VyqNYAma9w__&Key-Pair-Id=APKAIE5G5CRDK6RD3PGA"}
                    />
                  </Card>
                  <div className="text-center">
                    <h3 className="title">
                      Introduction
                    </h3>
                  </div>
                  <div className="article-content">
                    <p>
                    Deep brain stimulation (DBS) is a common therapy for medication-refractory Parkinson’s disease that involves surgically 
                    implanting electrodes into the basal ganglia and delivering electrical stimulation to modulate neural activity in brain networks 
                    and alleviate symptoms. Many individuals who undergo DBS therapy for Parkinson’s disease experience substantial improvement in their 
                    Parkinsonian symptoms and quality of life. However, clinical outcomes vary across individuals and several challenges remain that 
                    may hinder optimization of the therapy, including determining the optimal lead location and stimulation parameters.
                    <br/>
                    <br/>
                    Historically, the subthalamic nucleus (STN) has been the most common brain structure targeted for DBS for Parkinson’s disease. 
                    However, the globus pallidus internus (GPi) has been increasingly adopted as a target and has been shown to have advantages over 
                    the STN in suppression of dyskinesias, ease of programming, long-term flexibility in medication management, and safety benefits 
                    in the setting of mild cognitive decline and/or depression. Clinical outcomes rely on implanting the DBS lead into a precise 
                    location within these subcortical structures, and then empirically identifying the optimal stimulation parameters for reduction 
                    of symptoms. There has been great interest in investigating markers of therapeutic STN DBS based on imaging7,8 or neural signals. 
                    Recently, evoked resonant neural activity (ERNA), a high-frequency (∼200–500 Hz) stimulation-evoked response has been proposed 
                    as a candidate neural signal. ERNA has been hypothesized to originate from reciprocal connections between the STN and the pallidum, 
                    although its exact origins are unclear. Studies of STN DBS have suggested that ERNA may be localized to the therapeutic target 
                    region and may be correlated with postoperative stimulation parameters and possibly reduction in motor symptoms in Parkinson’s disease.
                    <br/>
                    <br/>
                    Despite robust work on STN ERNA, few studies have focused on identifying markers for GPi DBS or determining the optimal location to 
                    stimulate within the GPi target. In this study, we investigated pallidal ERNA as a potential candidate marker to guide DBS therapy for 
                    Parkinson’s disease. We aimed to determine if ERNA is a common feature across individuals and if the spatial topography of ERNA could be 
                    used to determine specific anatomical substructures. Finally, we aimed to evaluate whether ERNA is correlated with empirically derived 
                    postoperative stimulation parameters identified by expert clinicians.
                    </p>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto mt-5" md="8">
                  <ReactImageGallery items={ProjectFigures}/>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterWhite />
    </>
  );
};
