/*!

=========================================================
* Paper Kit PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2023 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";

// pages
import Index from "views/Index.js";

import Members from "views/Members.js";
import MembersProfile from "views/members/MembersProfile";
import { MembersInfo } from "views/members/Members";

import News from "views/News";
import { NewsContents } from "views/news/index.js";

import ERNA from "views/projects/ERNA";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/index" element={<Index />} />
      <Route exact path="/news" element={<News />} />
      {NewsContents.map((news) => (
        <Route exact key={news.link} path={news.link} element={news.component} />
      ))}
      <Route path="/news/*" element={<Navigate to="/news" replace />} />
      
      <Route exact path="/members" element={<Members />} />
      {MembersInfo.map((member) => (
        <Route exact key={member.key} path={member.link} element={<MembersProfile member={member} />} />
      ))}
      <Route path="/members/*" element={<Navigate to="/members" replace />} />

      <Route exact path="/projects/ERNA" element={<ERNA />} />


      <Route path="*" element={<Navigate to="/index" replace />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
/*
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/index" element={<Index />} />
      <Route exact path="/news" element={<News />} />
      {NewsContents.map((news) => (
        <Route exact key={news.link} path={news.link} element={news.component} />
      ))}
      <Route path="/news/*" element={<Navigate to="/news" replace />} />
      
      <Route exact path="/members" element={<Members />} />
      {MembersInfo.map((member) => (
        <Route exact key={member.key} path={member.link} element={<MembersProfile member={member} />} />
      ))}
      <Route path="/members/*" element={<Navigate to="/members" replace />} />

      <Route exact path="/projects/ERNA" element={<ERNA />} />


      <Route path="*" element={<Navigate to="/index" replace />} />
    </Routes>
  </BrowserRouter>
);
*/