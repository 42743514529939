import React from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import { NewsContents } from "./news/index.js";

function News() {
  document.documentElement.classList.remove("nav-open");
  const navigate = useNavigate();

  const [currentNews, setCurrentNews] = React.useState([]);
  const [showPages, setShowPages] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(-1);

  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  }, []);

  const makeInt = (pageId) => {
    try {
      return parseInt(pageId);
    } catch (error) {
      return 0;
    }
  }

  React.useEffect(() => {
    const maxPage = Math.ceil(NewsContents.length / 25);

    if (window.location.href.lastIndexOf("#page") >= 0) {
      var href = window.location.href.substring(
        window.location.href.lastIndexOf("#page") + 5
      );
      
      const jumpPage = makeInt(href);
      if (jumpPage > 0 && jumpPage <= maxPage) {
        setCurrentPage(jumpPage);
      } else {
        setCurrentPage(1);
      }
    } else {
      setCurrentPage(1);
    }
  }, []);
  
  React.useEffect(() => {
    const maxPage = Math.ceil(NewsContents.length / 25);
    const startPage = currentPage - 3 <= 0 ? 1 : currentPage - 3;
    let pageList = [];
    for (let i = startPage; i < startPage + 7; i++) {
      if (i <= maxPage) pageList.push(i);
    }
    setShowPages(pageList);

    setCurrentNews(NewsContents.filter((value, index) => {
      return index >= (currentPage-1)*25 && index < (currentPage)*25;
    }));
  }, [currentPage]);

  return (
    <>
      <ColorNavbar />
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" + require("assets/img/SharpenedNeuralSignalImageMax.png") + ")",
        }}
      >
        <div className="filter filter-secondary" />
        <div className="content-center">
          <Container>
            <h1>
              News
            </h1>
            <h3>Recent Activities of Neuromodulation Lab.</h3>
          </Container>
        </div>
      </div>
      <div className="main">
        {currentPage < 0 ? (
          <div className="section">
            <Container style={{maxWidth: 1500, display: "flex", justifyContent: "center"}}>
              <Spinner type={"grow"} className="mr-3" />
              <Spinner type={"grow"} className="mr-3" />
              <Spinner type={"grow"} className="mr-3" />
            </Container>
          </div>
        ) : (
          <div className="section">
            <Container style={{maxWidth: 860}}>
              {currentNews.map((news) => {
                return (
                  <Card key={news.link} className="card-plain card-blog">
                    <Row>
                      <Col md="4">
                        <div className="card-image">
                          <img
                            alt="..."
                            className="img"
                            src={news.image ? news.image : require("assets/img/Norman-Fixel-Center-for-Movement-Disorders_Professional.jpg")}
                          />
                        </div>
                      </Col>
                      <Col md="8">
                        <CardBody style={{paddingTop: 10}}>
                          <h6 className="card-category text-info" style={{paddingTop: 0}}>{news.type}</h6>
                          <CardTitle tag="h3">
                            <a href="#pablo" onClick={(e) => {
                              e.preventDefault(); 
                              navigate(news.link);
                            }}>
                              <b>{news.title}</b>
                            </a>
                          </CardTitle>
                          <p className="card-description">
                            {news.shortContent}
                            <a href={news.link}>
                              Read More
                            </a>
                          </p>
                          <p className="author">
                            by{" "}
                            <b>{news.author}</b>
                            {", "}
                            {new Date(news.date).toLocaleDateString("en", {year: "numeric", month: "long", day: "2-digit"})}
                          </p>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                )
              })}
            </Container>
            
            <div className="pagination-area">
              <Pagination
                className="pagination pagination-primary justify-content-center"
                listClassName="pagination-primary justify-content-center"
              >
                <PaginationItem>
                  <PaginationLink
                    href={"#page" + (currentPage).toFixed(0)}
                    onClick={(e) => {
                      e.currentTarget.blur();
                      if (currentPage > 1) {
                        setCurrentPage(currentPage-1);
                      }
                    }}
                  >
                    <i
                      aria-hidden={true}
                      className="fa fa-angle-double-left"
                    />
                  </PaginationLink>
                </PaginationItem>
                {showPages.map((pageId) => {
                  return <PaginationItem key={pageId} className={pageId == currentPage ? "active" : ""}>
                  <PaginationLink 
                    href={"#page" + (pageId).toFixed(0)}
                    onClick={() => {
                      setCurrentPage(pageId);
                    }}
                    style={{display: "flex", justifyContent: "center", alignItems: "center", width: 36, height: 36}}
                  >
                    {pageId}
                  </PaginationLink>
                </PaginationItem>
                })}
                <PaginationItem>
                  <PaginationLink
                    href={"#page" + (currentPage).toFixed(0)}
                    onClick={(e) => {
                      e.currentTarget.blur();
                      if (currentPage < showPages[showPages.length-1]) {
                        setCurrentPage(currentPage+1);
                      }
                    }}
                  >
                    <i
                      aria-hidden={true}
                      className="fa fa-angle-double-right"
                    />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          </div>
        )}
      </div>
      <FooterWhite />
    </>
  );
}

export default News;
