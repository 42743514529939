import React from "react";
import { useNavigate } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import { MembersInfo } from "./members/Members";

function Members() {
  document.documentElement.classList.remove("nav-open");
  const navigate = useNavigate();

  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  }, []);

  React.useEffect(() => {
    let roles = [];
    for (let i in MembersInfo) {
      if (!roles.includes(MembersInfo[i].role)) {
        roles.push(MembersInfo[i].role);
      }
    }
    setRoles(roles);
  }, []);

  const getShortBio = (bio) => {
    if (bio.length > 500) {
      const splitIndex = bio.indexOf(" ", 500);
      return bio.slice(0, splitIndex) + " ... ";
    } 
    return bio;
  };

  

  return (
    <>
      <ColorNavbar />
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" + require("assets/img/SharpenedNeuralSignalImageMax.png") + ")",
        }}
      >
        <div className="filter filter-secondary" />
        <div className="content-center">
          <Container>
            <h1 style={{fontWeight: "bold"}}>
              Team Members
            </h1>
            <h3>See our members of the Neuromodulation Lab.</h3>
          </Container>
        </div>
      </div>
      <div className="main">
        <div className="section">
          <Container style={{maxWidth: 1500}}>
              {roles.map((role) => {
                return (
                <Row key={role}>
                  <Col className="ml-auto mr-auto" md="10">
                    <h2 className="title">{role}</h2>
                    <br />
                    {MembersInfo.map((member) => {
                      if (member.role != role) return;
                      return (
                        <Card key={member.name} className="card-plain card-blog">
                          <Row>
                            <Col md="4" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                              <div className="card-image">
                                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img"
                                    src={member.profile}
                                  />
                                </a>
                              </div>
                            </Col>
                            <Col md="8">
                              <CardBody>
                                <h6 className="card-category text-info">{member.position}</h6>
                                <CardTitle tag="h3">
                                  <a href="#pablo" onClick={(e) => {
                                    e.preventDefault(); 
                                    navigate(member.link);
                                  }}>
                                    <b>{member.name}</b>
                                  </a>
                                </CardTitle>
                                <p className="card-description">
                                  {getShortBio(member.bio)}
                                  <a href={member.link}>
                                    Read More
                                  </a>
                                </p>
                              </CardBody>
                            </Col>
                          </Row>
                        </Card>
                      )
                    })}
                  </Col>
                </Row>
                )
              })}
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default Members;
