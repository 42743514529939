import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  Media,
  Container,
  Row,
  Col,
} from "reactstrap";

function PublicationMedia({paper, searchKey}) {
  console.log(paper)

  const getAuthorList = () => {
    if (paper.AltmetricContributors) {
      return paper.AltmetricContributors.map((author, index) => {
        if (index != paper.AltmetricContributors.length-1) {
          return <span style={searchKey.includes(author) ? {color: "red", fontWeight: "bold"} : {}}>{author + ", "}</span>
        } else {
          return <span>{author}</span>
        }
      })
    } else {
      return paper.Contributors.map((author, index) => {
        if (index != paper.Contributors.length-1) {
          return <span style={searchKey.includes(author) ? {color: "red", fontWeight: "bold"} : {}}>{author + ", "}</span>
        } else {
          return <span>{author}</span>
        }
      })
    }
    
  }

  return (
    <Media>
        <Media body style={{marginBottom: 0, paddingBottom: 5}}>
          <Row>
            <Col xs={"10"}>
              <a href={"http://dx.doi.org/" + paper.DOI} target={"_blank"}>
                <Media heading tag="h5" style={{marginBottom: 5, wordBreak: "normal"}}>
                  {paper.Title}
                </Media>
              </a>
              <p>
                <b>{paper.Journal ? paper.Journal : "Preprint"}</b>
                {", "}
                <i>{paper.Date}</i>
              </p>
                <div className="altmetric-embed mt-0" data-badge-popover={"top"} data-badge-type="1" data-doi={paper.DOI}></div>
            </Col>
            <Col xs={"2"}>
            <div className="pull-right">
              <span className="__dimensions_badge_embed__" 
                data-doi={paper.DOI}
                data-style="small_circle"
                data-legend="hover-top">
              </span>
            </div>
            </Col>
          </Row>
          <p>
            {getAuthorList()}
          </p>
          
        </Media>
    </Media>
  )
}
export default PublicationMedia;
