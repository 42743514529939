import React from "react";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  Media,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import PublicationMedia from "components/PublicationMedia";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import axios from "axios";
import { Link } from "react-router-dom";

export default function MembersProfile({member, children}) {
  const [publications, setPublications] = React.useState([]);
  const [education, setEducation] = React.useState(false);

  const [showAll, setShowAll] = React.useState(false);
  const buttonRef = React.createRef();

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  }, []);

  React.useEffect(() => {
    axios.get("https://lab-resources.jcagle.solutions/member_research_outputs.json" + "?request-date=" + Date.now().toFixed()).then((response) => {
      console.log(response.data)
      if (response.data[member.key].Education) {
        setEducation(response.data[member.key].Education[0]);
      }
      if (response.data[member.key].Publications) {
        setPublications(response.data[member.key].Publications);
      }
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  React.useEffect(() => {
    window.__dimensions_embed.addBadges();
    window._altmetric_embed_init();
  }, [publications, showAll]);

  return (
    <>
      <ColorNavbar />
      <div
        className="page-header"
        style={{
          backgroundImage: 
            "url(" + member.header + ")",
        }}
      >
        <div className="filter"/>
          <div className="content-center" style={member.header ? {
            backgroundColor: "#000000AA",
            maxWidth: 800,
            borderRadius: 25,
            padding: 25
          } : {}}>
            <div className="motto">
              <h1 className="text-center" style={{fontWeight: "bolder"}}>{member.name}</h1>
              <h3 className="text-center" style={{fontWeight: "bold"}}>{member.position}</h3>
              <br />
            </div>
            
          </div>
      </div>

      <div className="wrapper">
          <div className="profile-content section">
            <Container style={{maxWidth: 900}}>
              <Row>
                <Col md={12}>
                  <div className=" media-area" style={{marginLeft: 25, marginRight: 25}}>
                    <h2 className="text-center"><b>Introduction</b></h2>
                    <Media>
                      <Media className="text-center" body style={{marginBottom: 0, paddingBottom: 10}}>
                        <p style={{fontSize: 15, wordBreak: "break-word"}}>
                          {member.bio}
                        </p>
                      </Media>
                    </Media>
                  </div>
                </Col>
                {education ? (
                <Col md={12}>
                  <div className=" media-area" style={{marginLeft: 25, marginRight: 25}}>
                    <h2 className="text-center"><b>Education</b></h2>
                    <Media>
                      <Media className="text-center" body style={{marginBottom: 0, paddingBottom: 10}}>
                        <Media heading tag="h5" style={{marginBottom: 5, wordBreak: "normal"}}>
                          {education.Degree}
                        </Media>
                        <h6>
                          {member.education}
                        </h6>
                        <p>
                          {education.StartYear == 0 ? "" : education.StartYear + " -"}  {education.EndYear == 0 ? "" : education.EndYear}
                          <br/>
                          Department of {education.Department}
                          <br/>
                          {education.Location}
                        </p>
                      </Media>
                    </Media>
                  </div>
                </Col>
                ) : null}
              </Row>
            </Container>
          </div>
        <div className="profile-content section section-gray">
          <Container style={{maxWidth: 900}}>
            <div className="comments media-area">
              <h2 className="text-center"><b>Publications</b></h2>
              {publications.map((paper, index) => {
                if (!showAll && index > 8) return;
                return (
                  <PublicationMedia key={paper.Title} paper={paper} searchKey={member.searchKey}/>
                );
              })}
              <div ref={buttonRef} style={{display: "flex", justifyContent: "center", padding: 20}}>
                <Button
                  color="neutral"
                  size="lg"
                  type="button"
                  onClick={() => setShowAll(true)}
                  style={{display: !showAll ? "block" : "none"}}
                >
                  Show All
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
