import React from "react";

import {
  Container,
  Row, 
  Col,
  Button,
} from "reactstrap";

export default function Section({id}) {
  return (
    <div id={id} className="section text-center landing-section">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="title">Our Mission</h2>
            <h5>
              This is the paragraph where you can write more details about
              our missions, goals, and stuff.
            </h5>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon nc-palette" />
              </div>
              <div className="description">
                <h4 className="info-title">Improve Therapy</h4>
                <p className="description">
                  Improve Therapeutic Outcome of Patients with Movement Disorders
                </p>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon nc-bulb-63" />
              </div>
              <div className="description">
                <h4 className="info-title">Better Pipelines</h4>
                <p>
                  Standardized Signal Processing Pipelines for Chronic Neural Recording Analysis
                </p>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="info">
              <div className="icon icon-danger">
                <i className="nc-icon nc-chart-bar-32" />
              </div>
              <div className="description">
                <h4 className="info-title">Electrophysiology</h4>
                <p>
                  Study and Discover Neural Biomarkers related to Symptoms and Therapeutic Improvement
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}