import React from "react";

// reactstrap components
import {
  
} from "reactstrap";

import MembersProfile from "./MembersProfile";

export const MembersInfo = [
  {
    name: "Coralie de Hemptinne, Ph.D.", 
    position: "Principle Investigator",
    role: "Principle Investigator",
    key: "CoraDeHemptinne", 
    searchKey: [""], 
    link: "/members/cora-dehemptinne",
    header: "https://ufhealth.org/sites/default/files/web_fixel_building_03_2.jpg",
    profile: require("assets/img/members/Cora-de-Hemptinne_JJ204960-600x400.jpg"),
    bio: `
    Dr. Coralie de Hemptinne is an assistant professor in the Normal Fixel Institute for Neurological Diseases. 
      She earned her B.S degree in Biology from the Universite Libre de Bruxelles and her MS and PhD in Neuroscience 
      from the Universite Catholique de Louvain in Belgium. She worked in Dr. Philip Starr's laboratory at UCSF as a 
      postdoctoral fellow and then as a research associate from 2010-2020. The current aim of her research is to understand 
      the pathophysiology underlying motor and non-motor symptoms in Parkinson’s disease patients, using invasive chronic, 
      multisite recordings. Her ultimate goal is to develop new neuromodulation strategies for Parkinson’s disease and other movement disorders.
    `
  },
  {
    name: "Kara A. Johnson, Ph.D.", 
    position: "Postdoctoral Associate",
    role: "Staff",
    key: "KaraJohnson", 
    searchKey: ["Kara A. Johnson", "Kara A Johnson"], 
    link: "/members/kara-johnson",
    header: "https://ufhealth.org/sites/default/files/web_fixel_building_03_2.jpg",
    profile: require("assets/img/members/Kara-Johnson-8.jpg"),
    bio: `
    Dr. Johnson is a Postdoctoral Associate in the Department of Neurology at the University of Florida. 
      She obtained her B.S. and Ph.D. in biomedical engineering from the University of Utah. She has extensive 
      expertise in neuroimaging analysis, including tractography and connectomics, and computational models to study 
      the network effects of DBS. During her graduate training, she was awarded the National Science Foundation 
      Graduate Research Fellowship to study neuroimaging and connectomic markers of clinical response to DBS in 
      treatment-refractory Tourette syndrome. During her postdoctoral training, her research has pivoted to study 
      electrophysiology in movement disorders such as Parkinson’s disease. The ultimate goal of Dr. Johnson’s research 
      is to understand the pathophysiology of movement disorders and the mechanisms of DBS through innovative, multimodal 
      analyses that combine neuroimaging, computational modeling, and electrophysiology.
    `
  },
  {
    name: "Jackson N. Cagle, Ph.D.", 
    position: "Medical Scientist III",
    role: "Staff",
    key: "JacksonCagle", 
    searchKey: ["Jackson Cagle", "Jackson N. Cagle", "Jackson N Cagle", "Cagle J", "Cagle JN"], 
    link: "/members/jackson-cagle",
    header: "https://insideecology.com/wp-content/uploads/2018/03/Himalayan_bear-810x554.jpg",
    profile: require("assets/img/members/Jackson-Cagle-532x399.jpg"),
    bio: `
    Dr. Cagle is a Medical Scientist in Dr. De Hemptinne’s lab in the Department of Neurology 
      at the University of Florida. He obtained his B.S. and Ph.D. from the University of Florida. He has extensive 
      expertise in neural signal processing using both invasive and non-invasive neurological recordings and adative 
      DBS applied to movement disorders, including PD and Tourette. Dr. Cagle’s main goal is to develop tools 
      (software and algorithms) to record, analyze and visualize neurophysiological data to improve neuromodulation 
      therapy. Beyond academic, Jackson Cagle also has industrial experiences in software development for embedded smart 
      devices, space systems, and computer applications. 
      `,
  }
];
