/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Button } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <div className="social-area">
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="facebook"
                  href="https://www.facebook.com/FixelInstitute"
                  target="_blank"
                >
                  <i className="fa fa-facebook" />
                </Button>
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="twitter"
                  href="https://twitter.com/UFMDFellowship"
                  target="_blank"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="github"
                  href="https://github.com/Fixel-Institute"
                  target="_blank"
                >
                  <i className="fa fa-github" />
                </Button>
              </div>
            </nav>
            <div className="credits ml-auto" style={{display: "flex", alignItems: "center"}}>
              <span className="copyright">
                © {new Date().getFullYear()}
                , Neuromodulation Lab, University of Florida
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
