import React from "react";

// reactstrap components
import {
  Container,
  Row, 
  Col,
  Button,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

// sections for this page
import MissionStatements from "./LandingPage/MissionStatements.js";
import CurrentProjects from "./LandingPage/CurrentProjects.js";

import SectionButtons from "./index-sections/SectionButtons.js";
import SectionNavigation from "./index-sections/SectionNavigation.js";
import SectionNavbars from "./index-sections/SectionNavbars.js";
import SectionPreFooterAreas from "./index-sections/SectionPreFooterAreas.js";
import SectionFooterAreas from "./index-sections/SectionFooterAreas.js";
import SectionDescriptionAreas from "./index-sections/SectionDescriptionAreas.js";
import SectionTypography from "./index-sections/SectionTypography.js";
import SectionNotification from "./index-sections/SectionNotification.js";
import SectionTables from "./index-sections/SectionTables.js";
import SectionComments from "./index-sections/SectionComments.js";
import SectionCommentsAreaSmall from "./index-sections/SectionCommentsAreaSmall.js";
import SectionJavaScript from "./index-sections/SectionJavaScript.js";
import SectionCards from "./index-sections/SectionCards.js";

const Navigation = () => {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  const changeNavbarColor = () => {
    const height = 100;
    if (
      document.documentElement.scrollTop >= height ||
      document.body.scrollTop >= height
    ) {
      setShow(true);
    } else if (
      document.documentElement.scrollTop < height ||
      document.body.scrollTop < height
    ) {
      setShow(false);
    }
  };

  const navigationSection = [
    {
      tag: "mission-statements",
      label: "Mission Statements"
    },
    {
      tag: "current-projects",
      label: "Current Projects"
    }
  ]

  return (
    <nav id="cd-vertical-nav" style={{display: show ? "" : "none"}}>
      <ul>
        {navigationSection.map((section, index) => {
        return <li key={section.tag}>
          <a
            data-number={index.toFixed(0)}
            href={"#"+section.tag}
            onClick={(e) => {
              e.preventDefault();
              document.getElementById(section.tag).scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }}
          >
            <span className="cd-dot" />
            <span className="cd-label">{section.label}</span>
          </a>
        </li>
        })}
      </ul>
    </nav>
  )
}

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("section-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }

    // this function is used to make the right bullets list
    // (the bellow <nav id="cd-vertical-nav">)
    // active when changeing the section on scroll
    const updateView = () => {
      var contentSections = document.getElementsByClassName("cd-section");
      var navigationItems = document
        .getElementById("cd-vertical-nav")
        .getElementsByTagName("a");

      for (let i = 0; i < contentSections.length; i++) {
        var activeSection =
          parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
        if (
          contentSections[i].offsetTop - window.innerHeight / 2 <
            window.pageYOffset &&
          contentSections[i].offsetTop +
            contentSections[i].scrollHeight -
            window.innerHeight / 2 >
            window.pageYOffset
        ) {
          navigationItems[activeSection].classList.add("is-selected");
        } else {
          navigationItems[activeSection].classList.remove("is-selected");
        }
      }
    };

    window.addEventListener("scroll", updateView);
    return function cleanup() {
      document.body.classList.remove("section-page");
      window.removeEventListener("scroll", updateView);
    };
  });

  return (
    <>
      <ColorNavbar />
      <LandingPageHeader />
      <div className="wrapper">
        <MissionStatements id={"mission-statements"} />
        <CurrentProjects id={"current-projects"} />
      </div>
      <Navigation />
      
      <FooterBlack />
    </>
  );
}

export default Index;
